const FOOTER_DATA = [
  {
    title: 'Company',
    menuItem: [
      {
        link: '#team',
        label: 'About team',
        target: "",
      },
      /*{
        link: '#',
        label: 'Affiliate center',
      },
      {
        link: '#',
        label: 'Careers & Culture',
      },
      {
        link: '#',
        label: 'Blog news',
      },*/
      {
        link: 'mailto:jonathan.lehner@peer2panel.com',
        label: 'Press & media',
        target: "_blank",
      },
    ],
  },
  {
    title: 'About Us',
    menuItem: [
      {
        link: 'https://discord.com/invite/wa2fYQww2R',
        label: 'Support Center',
        target: "_blank",
      },
      {
        link: 'https://discord.com/invite/wa2fYQww2R',
        label: 'Customer Support',
        target: "_blank",
      },
      /*{
        link: '#',
        label: 'About Us',
      },
      {
        link: '#',
        label: 'Copyright',
      },
      {
        link: '#',
        label: 'Popular Campaign',
      },*/
    ],
  },
  /*{
    title: 'Our Information',
    menuItem: [
      {
        link: '#',
        label: 'Return Policy ',
      },
      {
        link: '#',
        label: 'Privacy Policy',
      },
      {
        link: '#',
        label: 'Terms & Conditions',
      },
      {
        link: '#',
        label: 'Site Map',
      },
      {
        link: '#',
        label: 'Store Hours',
      },
    ],
  },*/
  /*{
    title: 'My Account',
    menuItem: [
      {
        link: '#',
        label: 'Press inquiries',
      },
      {
        link: '#',
        label: 'Social media',
      },
      {
        link: '#',
        label: 'directories',
      },
      {
        link: '#',
        label: 'Images & B-roll',
      },
      {
        link: '#',
        label: 'Permissions',
      },
    ],
  },*/
  /*{
    title: 'Policy',
    menuItem: [
      {
        link: '#',
        label: 'Application security',
      },
      {
        link: '#',
        label: 'Software principles',
      },
      {
        link: '#',
        label: 'Unwanted software policy',
      },
      {
        link: '#',
        label: 'Responsible supply chain',
      },
    ],
  },*/
];
export default FOOTER_DATA;
