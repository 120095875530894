/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Image } from 'theme-ui';
import { Link } from 'components/link';
import logoPeer2Panel from 'assets/images/peer2panel_logo.jpg';

export default function Logo({ image }) {
  return (
    <Link
      path="/"
      sx={{
        variant: 'links.logo',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Image style={{height: "120px", width: "auto", objectFit: "cover"}} src={logoPeer2Panel} alt="startup landing logo" width={195} height={37} /> <span style={{fontSize: "20px", fontWeight: "bold", color: "black"}}></span>
    </Link>
  );
}
