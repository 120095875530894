const MENU_DATA = [
  {
    path: 'banner',
    label: 'Home',
  },
  {
    path: 'services',
    label: 'About',
  },
  {
    path: 'team',
    label: 'Team',
  },
  {
    path: 'contact',
    label: 'Contact',
  },
];
export default MENU_DATA;
